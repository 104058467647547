'use strict'
import { createAction } from 'redux-actions'
import axios from 'axios'
import * as storage from '../../utils/storage'

const axiosInstance = axios.create({
    baseURL: process.env.API_URL.toString(),
    timeout: 240000
})

//
// API - NOT FOR EXPORT
//

const getHeaders = function(params = {}, lang = 'es') {
    const headers = {
        'Accept': 'application/json',
        'Accept-Language': lang
    }

    const accessToken = storage.get(storage.accessTokenKey)
    if (accessToken) {
        headers['Authorization'] = 'Bearer ' + accessToken
    }

    return {
        headers,
        params
    }
}

const getHeadersAuthOnly = function(params = {}, lang = 'es') {
    const headers = {
        'Accept': 'application/json',
        'Accept-Language': lang
    }
    const accessToken = storage.get(storage.accessTokenKey)
    if (accessToken) {
        headers['Authorization'] = 'Bearer ' + accessToken
    }

    return {
        headers,
        params
    }
}

const apiCallGetExtra = function(method, url, data, config, extra, response, startTime) {
    let toReturn = {
        ga: {
            url: url,
            method: method,
            status: response && response.status,
            time: new Date().getTime() - startTime
        }
    }
    if (extra) {
        toReturn = Object.assign(toReturn, extra)
    }
    return toReturn
}

const apiCall = function(method, url, data, config, extra) {
    const startTime = new Date().getTime()
    // axios#get(url[, config])
    // axios#delete(url[, config])
    // axios#head(url[, config])
    // axios#options(url[, config])
    // axios#post(url[, data[, config]])
    // axios#put(url[, data[, config]])
    // axios#patch(url[, data[, config]])
    return axiosInstance[method](url, data, config).then(function(response) {
        response.extra = apiCallGetExtra(method, url, data, config, extra, response, startTime)
        if (response.status >= 200 && response.status < 300) {
            return Promise.resolve(response)
        } else {
            return Promise.reject(response)
        }
    }).catch(function(error) {
        return Promise.reject({
            error: error,
            apiCall: true,
            apiCallStatus: error && error.response && error.response.status || 'unknown',
            extra: apiCallGetExtra(method, url, data, config, extra, error && error.response, startTime)
        })
    })
}

//
// ACTIONS - FOR EXPORT
//

// CLEAN ALL
export const CLEAN_ALL = 'CLEAN_ALL'

export const LOGIN_USER = 'LOGIN_USER'
export const USER_ADD = 'USER_ADD'
export const USER_EDIT = 'USER_EDIT'
export const USER_DELETE = 'USER_DELETE'
export const USER_GET = 'USER_GET'
export const USER_GET_ONE = 'USER_GET_ONE'
export const USER_CLEAN = 'USER_CLEAN'
export const USER_CHANGE_PASSWORD = 'USER_CHANGE_PASSWORD'
export const USER_GET_PROFILE = 'USER_GET_PROFILE'
export const USER_CLEAN_PROFILE = 'USER_CLEAN_PROFILE'
export const APP_ADD = 'APP_ADD'
export const APP_EDIT = 'APP_EDIT'
export const APP_EDIT_SEARCH = 'APP_EDIT_SEARCH'
export const APP_EDIT_THEME = 'APP_EDIT_THEME'
export const APP_DELETE = 'APP_DELETE'
export const APP_UNDELETE = 'APP_UNDELETE'
export const APP_GET = 'APP_GET'
export const APP_GET_ONE = 'APP_GET_ONE'
export const APP_GET_ONE_CODENAME = 'APP_GET_ONE_CODENAME'
export const APP_CLEAN = 'APP_CLEAN'
export const APP_CLEAN_ONE = 'APP_CLEAN_ONE'
export const APP_SELECT = 'APP_SELECT'
export const APP_NEW_TOKEN = 'APP_NEW_TOKEN'
export const MENU_CONFIG = 'MENU_CONFIG'
export const MESSAGE_GET_ONE = 'MESSAGE_GET_ONE'
export const MESSAGE_GET_NEW = 'MESSAGE_GET_NEW'
export const MESSAGE_EDIT = 'MESSAGE_EDIT'
export const MESSAGE_DELETE = 'MESSAGE_DELETE'
export const MESSAGE_USER_GET = 'MESSAGE_USER_GET'
export const MESSAGE_USER_CLEAN = 'MESSAGE_USER_CLEAN'
export const MESSAGE_USER_GET_ONE = 'MESSAGE_USER_GET_ONE'
export const MESSAGE_USER_IMPORT = 'MESSAGE_USER_IMPORT'
export const MESSAGE_USER_EXPORT = 'MESSAGE_USER_EXPORT'
export const MESSAGE_USER_BLOCK = 'MESSAGE_USER_BLOCK'
export const API_CLEAN = 'API_CLEAN'
export const IS_READY = 'IS_READY'
export const SUBMISSION_GET = 'SUBMISSION_GET'
export const SUBMISSION_EDIT = 'SUBMISSION_EDIT'
export const SUBMISSION_CLEAN = 'SUBMISSION_CLEAN'
export const WEBHOOK_GET = 'WEBHOOK_GET'
export const WEBHOOK_GET_ONE = 'WEBHOOK_GET_ONE'
export const GET_USER_WEBHOOK = 'GET_USER_WEBHOOK'
export const WEBHOOK_BROADCAST_GET = 'WEBHOOK_BROADCAST_GET'
export const WEBHOOK_ADD = 'WEBHOOK_ADD'
export const WEBHOOK_EDIT = 'WEBHOOK_EDIT'
export const WEBHOOK_SET = 'WEBHOOK_SET'
export const WEBHOOK_DELETE = 'WEBHOOK_DELETE'
export const WEBHOOK_CLEAN = 'WEBHOOK_CLEAN'
export const WEBHOOK_APPLY = 'WEBHOOK_APPLY'
export const ACCOUNT_GET = 'ACCOUNT_GET'
export const ACCOUNT_GET_NAMEANDIDS = 'ACCOUNT_GET_NAMEANDIDS'
export const ACCOUNT_CLEAN = 'ACCOUNT_CLEAN'
export const ACCOUNT_ADD = 'ACCOUNT_ADD'
export const ACCOUNT_DELETE = 'ACCOUNT_DELETE'
export const ACCOUNT_EDIT = 'ACCOUNT_EDIT'
export const ACCOUNT_GET_ONE = 'ACCOUNT_GET_ONE'
export const ACCOUNT_CLEAN_ONE = 'ACCOUNT_CLEAN_ONE'
export const STATS_CHART_COUNT_GET = 'STATS_CHART_COUNT_GET'
export const STATS_TRENDING_COUNT_GET = 'STATS_TRENDING_COUNT_GET'
export const EXPORT_REPORT = 'EXPORT_REPORT'
export const EXPORT_REPORT_V2C = 'EXPORT_REPORT_V2C'
export const EXPORT_NPS = 'EXPORT_NPS'
export const EXPORT_REPORT_OPERATORS = 'EXPORT_REPORT_OPERATORS'
export const DOWNLOAD_TRENDING = 'DOWNLOAD_TRENDING'
export const APP_DATA = 'APP_DATA'
export const KUE_STATS_GET = 'KUE_STATS_GET'
export const KUE_STATS_DO_OPERATION = 'KUE_STATS_DO_OPERATION'
export const KUE_STATS_CLEAR = 'KUE_STATS_CLEAR'
export const BULL_STATS_GET = 'BULL_STATS_GET'
export const BOTS_STATS_GET = 'BOTS_STATS_GET'
export const UPTIME_STATS_GET = 'UPTIME_STATS_GET'
export const V2C_STATS_GET = 'V2C_STATS_GET'
export const GUPSHUP_BALANCE_STATS_GET = 'GUPSHUP_BALANCE_STATS_GET'
export const GUPSHUP_BALANCE_STATS_GET_FOR_APP = 'GUPSHUP_BALANCE_STATS_GET_FOR_APP'
export const HEAPSNAPSHOT_GET = 'HEAPSNAPSHOT_GET'

// AI CONDITION
export const ADD_AI_CONDITION = 'ADD_AI_CONDITION'
export const EDIT_AI_CONDITION = 'EDIT_AI_CONDITION'
export const DELETE_AI_CONDITION = 'DELETE_AI_CONDITION'
export const GET_AI_CONDITIONS = 'GET_AI_CONDITIONS'
export const GET_AI_CONDITION = 'GET_AI_CONDITION'
export const CLEAN_AI_CONDITIONS = 'CLEAN_AI_CONDITIONS'
export const CLEAN_AI_CONDITION = 'CLEAN_AI_CONDITION'
export const DISABLE_AI_CONDITION = 'DISABLE_AI_CONDITION'
export const MATCH_AI_CONDITION = 'MATCH_AI_CONDITION'

// AI ENTITIES
export const GET_AI_ENTITIES = 'GET_AI_ENTITIES'
export const ADD_AI_ENTITY = 'ADD_AI_ENTITY'
export const EDIT_AI_ENTITY = 'EDIT_AI_ENTITY'
export const DELETE_AI_ENTITY = 'DELETE_AI_ENTITY'
export const CLEAN_AI_ENTITIES = 'CLEAN_AI_ENTITIES'
export const ADD_AI_ENTITY_VALUE = 'ADD_AI_ENTITY_VALUE'
export const DELETE_AI_ENTITY_VALUE = 'DELETE_AI_ENTITY_VALUE'
export const ADD_AI_SYNONYM = 'ADD_AI_SYNONYM'
export const DELETE_AI_SYNONYM = 'DELETE_AI_SYNONYM'
export const GET_AI_ANALYSIS = 'GET_AI_ANALYSIS'
export const GET_AI_SAMPLES = 'GET_AI_SAMPLES'
export const CLEAN_AI_SAMPLES = 'CLEAN_AI_SAMPLES'
export const ADD_AI_SAMPLES = 'ADD_AI_SAMPLES'
export const DELETE_AI_SAMPLES = 'DELETE_AI_SAMPLES'
export const GET_AI_STATUS = 'GET_AI_STATUS'

// AI TASKS
export const GET_AI_EXPRESSIONS = 'GET_AI_EXPRESSIONS'
export const CLEAN_AI_EXPRESSIONS = 'CLEAN_AI_EXPRESSIONS'
export const EDIT_AI_EXPRESSION = 'EDIT_AI_EXPRESSION'
export const GET_AI_TASKS = 'GET_AI_TASKS'
export const CLEAN_AI_TASKS = 'CLEAN_AI_TASKS'
export const ADD_AI_TASKS = 'ADD_AI_TASKS'
export const DOWNLOAD_AI_TASKS = 'DOWNLOAD_AI_TASKS'
export const ARCHIVE_AI_TASK = 'ARCHIVE_AI_TASK'
export const DELETE_AI_TASKS = 'DELETE_AI_TASKS'
export const UPDATE_STATUS_AI_TASKS = 'UPDATE_STATUS_AI_TASKS'
export const GET_AI_TASK = 'GET_AI_TASK'
export const CLEAN_AI_TASK = 'CLEAN_AI_TASK'
export const TRAIN_TASK = 'TRAIN_TASK'

// AI NLP
export const DOWNLOAD_AI_NLP = 'DOWNLOAD_AI_NLP'

// AI WIT
export const BACKUPS_WIT = 'BACKUPS_WIT'

// PUBLIC FLOWS
export const GET_PUBLIC_FLOWS = 'GET_PUBLIC_FLOWS'
export const CLEAN_PUBLIC_FLOWS = 'CLEAN_PUBLIC_FLOWS'

// PUBLIC RESOURCES
export const GET_PUBLIC_RESOURCES = 'GET_PUBLIC_RESOURCES'

// RESOURCES
export const RESOURCE_ADD = 'RESOURCE_ADD'
export const RESOURCE_EDIT = 'RESOURCE_EDIT'
export const RESOURCE_CLEAN = 'RESOURCE_CLEAN'
export const RESOURCE_DELETE = 'RESOURCE_DELETE'
export const RESOURCE_GET_ALL = 'RESOURCE_GET_ALL'
export const RESOURCE_GET_ALL_CLEAN = 'RESOURCE_GET_ALL_CLEAN'
export const RESOURCE_GET_ONE = 'RESOURCE_GET_ONE'
export const RESOURCE_GET_ONE_CLEAN = 'RESOURCE_GET_ONE_CLEAN'
export const RESOURCE_UPLOAD_MEDIA = 'RESOURCE_UPLOAD_MEDIA'
export const RESOURCE_UPLOAD_MEDIA_CLEAN = 'RESOURCE_UPLOAD_MEDIA_CLEAN'
export const RESOURCE_DELETE_ALL_MEDIA = 'RESOURCE_DELETE_ALL_MEDIA'
export const RESOURCE_DELETE_MEDIA = 'RESOURCE_DELETE_MEDIA'
export const RESOURCE_DELETE_MEDIA_CLEAN = 'RESOURCE_DELETE_MEDIA_CLEAN'
export const SAVE_ASSET_FACEBOOK = 'SAVE_ASSET_FACEBOOK'
export const CLEAN_SAVE_ASSET_FACEBOOK = 'CLEAN_SAVE_ASSET_FACEBOOK'
export const SAVE_ASSET_TELEGRAM = 'SAVE_ASSET_TELEGRAM'
export const CLEAN_SAVE_ASSET_TELEGRAM = 'CLEAN_SAVE_ASSET_TELEGRAM'

// WHATSAPP HSM
export const SEND_HSM = 'SEND_HSM'

// HSM
export const GET_HSM = 'GET_HSM'
export const CLEAN_HSM = 'CLEAN_HSM'
export const EXPORT_HSM = 'EXPORT_HSM'
export const UPLOAD_HSM_CAMPAIGN = 'UPLOAD_HSM_CAMPAIGN'
export const GET_LIMIT_HSM_SEND = 'GET_LIMIT_HSM_SEND'
export const UPDATE_LIMIT_HSM_SEND = 'UPDATE_LIMIT_HSM_SEND'
export const GET_HSM_TEMPLATE = 'GET_HSM_TEMPLATE'
export const GET_HSM_TEMPLATES = 'GET_HSM_TEMPLATES'
export const CLEAN_HSM_TEMPLATES = 'CLEAN_HSM_TEMPLATES'
export const ADD_HSM_TEMPLATE = 'ADD_HSM_TEMPLATE'
export const EDIT_HSM_TEMPLATE = 'EDIT_HSM_TEMPLATE'
export const DELETE_HSM_TEMPLATE = 'DELETE_HSM_TEMPLATE'

// UNKNOWN_EXPRESSION
export const UNKNOWN_EXPRESSION_GET = 'UNKNOWN_EXPRESSION_GET'
export const UNKNOWN_EXPRESSION_CLEAN = 'UNKNOWN_EXPRESSION_CLEAN'
export const UNKNOWN_EXPRESSION_DELETE = 'UNKNOWN_EXPRESSION_DELETE'
export const UNKNOWN_EXPRESSION_UNDELETE = 'UNKNOWN_EXPRESSION_UNDELETE'
export const DOWNLOAD_UNKNOWN_EXPRESSION = 'DOWNLOAD_UNKNOWN_EXPRESSION'
export const UNKNOWN_EXPRESSION_CLEAN_ALL = "UNKNOWN_EXPRESSION_CLEAN_ALL"

// UNKNOWN_CONVERSATION
export const UNKNOWN_CONVERSATION_GET = 'UNKNOWN_CONVERSATION_GET'
export const UNKNOWN_CONVERSATION_CLEAN = 'UNKNOWN_CONVERSATION_CLEAN'

// UNKNOWN_CATEGORY_CLEAN
export const UNKNOWN_CATEGORY_GET = 'UNKNOWN_CATEGORY_GET'
export const UNKNOWN_CATEGORY_CLEAN = 'UNKNOWN_CATEGORY_CLEAN'

// GLOBAL LIMIT
export const CHANGED_GLOBAL_LIMIT = 'CHANGED_GLOBAL_LIMIT'

// SKIP
export const CHANGED_SKIP = 'CHANGED_SKIP'
export const CLEAN_CHANGED_SKIP = 'CLEAN_CHANGED_SKIP'

// UNKNOWN FILTER
export const UNKNOWN_FILTER_GET = 'UNKNOWN_FILTER_GET'
export const UNKNOWN_FILTER_CLEAN = 'UNKNOWN_FILTER_CLEAN'

// CONVERSATIONS
export const GET_CONVERSATION = 'GET_CONVERSATION'
export const CLEAN_CONVERSATION = 'CLEAN_CONVERSATION'
export const GET_CONVERSATIONS = 'GET_CONVERSATIONS'
export const CLEAN_CONVERSATIONS = 'CLEAN_CONVERSATIONS'
export const WEBHOOK_ACTION = 'WEBHOOK_ACTION'
export const LOAD_CACHE = 'LOAD_CACHE'
export const SAVE_CACHE = 'SAVE_CACHE'
export const CLEAN_CACHE = 'CLEAN_CACHE'
export const GET_MESSAGES = 'GET_MESSAGES'
export const GET_OLD_MESSAGES = 'GET_OLD_MESSAGES'
export const GET_NEW_MESSAGES = 'GET_NEW_MESSAGES'
export const RECEIVE_MESSAGE = 'RECEIVE_MESSAGE'
export const UPDATE_MESSAGE = 'UPDATE_MESSAGE'
export const CLEAN_MESSAGES = 'CLEAN_MESSAGES'
export const PAUSE = 'PAUSE'
export const UNPAUSE = 'UNPAUSE'
export const MESSAGE_RECEIVE = 'MESSAGE_RECEIVE'
export const SPEECH_TO_TEXT = 'SPEECH_TO_TEXT'

// SEND EMAIL
export const SEND_EMAIL = 'SEND_EMAIL'

// UPLOAD
export const UPLOAD = 'UPLOAD'

// BIGQUERY
export const REQUEST_CHARTS_CONFIG_V2C = 'REQUEST_CHARTS_CONFIG_V2C'
export const REQUEST_CHART_DATA_V2C = 'REQUEST_CHART_DATA_V2C'
export const REQUEST_CHARTS_CONFIG = 'REQUEST_CHARTS_CONFIG'
export const REQUEST_BIGQUERY_DATA = 'REQUEST_BIGQUERY_DATA'
export const REQUEST_CHART_DATA = 'REQUEST_CHART_DATA'

// LOCALES
export const GET_LOCALES = 'GET_LOCALES'
export const UPDATE_LOCALE = 'UPDATE_LOCALE'

// OPERATORS
export const GET_STATS_OPERATOR = 'GET_STATS_OPERATOR'
export const OPERATORS_CLEAN = 'OPERATORS_CLEAN'

// PATH
export const PREVIOUSPATH_SET = 'PREVIOUSPATH_SET'

// SERVICES
export const GET_SERVICES_CONFIG = 'GET_SERVICES_CONFIG'

// BOTS
export const BOTS_GET = 'BOTS_GET'
export const BOTS_CLEAN = 'BOTS_CLEAN'
export const BOTS_ADD = 'BOTS_ADD'
export const RELEASES_ADD = 'RELEASES_ADD'
export const RELEASES_DELETE = 'RELEASES_DELETE'
export const RELEASES_GET = 'RELEASES_GET'
export const RELEASES_DIFF = 'RELEASES_DIFF'
export const RELEASES_MERGE = 'RELEASES_MERGE'

// GLOBAL CONFIGURATIONS
export const GET_GLOBAL_CONFIG = 'GET_GLOBAL_CONFIG'
export const SELECT_BOT = 'SELECT_BOT'
export const SELECT_WIT = 'SELECT_WIT'

// BILLING
export const CHANGED_BILLING = 'CHANGED_BILLING'
export const GET_BILLING = 'GET_BILLING'

// DERIVE CUSTOMER
export const DERIVE_CUSTOMER = 'DERIVE_CUSTOMER'
export const GET_CUSTOMER = 'GET_CUSTOMER'
export const AVAILABILITY_CUSTOMER = 'AVAILABILITY_CUSTOMER'
export const GET_ORIGINS_CUSTOMER = 'GET_ORIGINS_CUSTOMER'
export const GET_OPERATOR_CUSTOMER = 'GET_OPERATOR_CUSTOMER'
export const CLEAN_CUSTOMER = 'CLEAN_CUSTOMER'

// STATUS
export const CLEAN_STATUS_CT = 'CLEAN_STATUS_CT'
export const SET_STATUS_CT = 'SET_STATUS_CT'

// FACEBOOK
export const FACEBOOK_APP_SUBSCRIPTIONS = 'FACEBOOK_APP_SUBSCRIPTIONS'


export const cleanAll = createAction(CLEAN_ALL, () => {
    storage.remove(storage.accessTokenKey)
    return true
})

export const userLogin = createAction(LOGIN_USER, function(user) {
    return apiCall('post', '/api/v1/user/login', user, getHeaders())
})

export const userAdd = createAction(USER_ADD, function(appCodename, user) {
    return apiCall('post', '/api/v1/' + appCodename + '/user', user, getHeaders())
})

export const userEdit = createAction(USER_EDIT, function(appCodename, userId, user) {
    return apiCall('put', '/api/v1/' + appCodename + '/user/' + userId, user, getHeaders())
})

export const userDelete = createAction(USER_DELETE, function(appCodename, userId) {
    return apiCall('delete', '/api/v1/' + appCodename + '/user/' + userId, getHeaders())
})

export const userGet = createAction(USER_GET, function(appCodename, query, params) {
    const queryParams = Object.assign({}, params, query)
    return apiCall('get', '/api/v1/' + appCodename + '/user', getHeaders(queryParams))
})

export const userGetOne = createAction(USER_GET_ONE, function(appCodename, userId) {
    return apiCall('get', '/api/v1/' + appCodename + '/user/' + userId, getHeaders())
})

export const userClean = createAction(USER_CLEAN, function() {
    return Promise.resolve()
})

export const userChangePassword = createAction(USER_CHANGE_PASSWORD, function(userId, user) {
    return apiCall('put', '/api/v1/user/' + userId + '/changePassword', user, getHeaders())
})

export const userGetProfile = createAction(USER_GET_PROFILE, function() {
    return apiCall('get', '/api/v1/user/me/profile', getHeaders())
})

export const userCleanProfile = createAction(USER_CLEAN_PROFILE, function() {
    return Promise.resolve()
})

export const appAdd = createAction(APP_ADD, function(app) {
    return apiCall('post', '/api/v1/app', app, getHeaders())
})

export const appEdit = createAction(APP_EDIT, function(appId, app) {
    return apiCall('put', '/api/v1/app/' + appId, app, getHeaders())
})

export const appMessageUserSearchEdit = createAction(APP_EDIT_SEARCH, function(appCodename, body) {
    return apiCall('put', '/api/v1/app/' + appCodename + '/update/search', body, getHeaders())
})

export const appThemeEdit = createAction(APP_EDIT_THEME, function(appCodename, theme) {
    return apiCall('put', '/api/v1/app/' + appCodename + '/theme', theme, getHeaders())
})

export const appDelete = createAction(APP_DELETE, function(appId) {
    return apiCall('delete', '/api/v1/app/' + appId, getHeaders())
})

export const appData = createAction(APP_DATA, function() {
    return apiCall('get', '/api/v1/app', getHeaders())
})

export const appNewToken = createAction(APP_NEW_TOKEN, function(appCodename) {
    return apiCall('get', '/api/v1/app/' + appCodename + '/newtoken', getHeaders())
})

export const getMenuConfig = createAction(MENU_CONFIG, (appCodename) => {
    return apiCall('get', `/api/v1/app/${appCodename}/menu`, getHeaders())
})

export const appGet = createAction(APP_GET, function(query, params) {
    const queryParams = Object.assign({}, params, query)
    return apiCall('get', '/api/v1/app', getHeaders(queryParams))
})

export const appGetOne = createAction(APP_GET_ONE, function(appId) {
    return apiCall('get', '/api/v1/app/' + appId, getHeaders())
})

export const appGetOneCodename = createAction(APP_GET_ONE_CODENAME, function(appCodename) {
    return apiCall('get', '/api/v1/app/codename/' + appCodename, getHeaders({ allowDeleted: true }))
})

export const appClean = createAction(APP_CLEAN, function() {
    return Promise.resolve()
})

export const appCleanOne = createAction(APP_CLEAN_ONE, function() {
    return Promise.resolve()
})

export const appSelect = createAction(APP_SELECT, function(app) {
    return Promise.resolve(app)
})

export const accountGet = createAction(ACCOUNT_GET, function(appCodename, query, params) {
    const queryParams = Object.assign({}, params, query)
    return apiCall('get', '/api/v1/' + appCodename + '/account', getHeaders(queryParams))
})

export const accountGetNamesAndIds = createAction(ACCOUNT_GET_NAMEANDIDS, function(appCodename, query, params) {
    const queryParams = Object.assign({}, params, query)
    return apiCall('get', '/api/v1/' + appCodename + '/account/get/namesandids', getHeaders(queryParams))
})

export const accountClean = createAction(ACCOUNT_CLEAN, function() {
    return Promise.resolve()
})

export const accountAdd = createAction(ACCOUNT_ADD, function(appCodename, account) {
    return apiCall('post', '/api/v1/' + appCodename + '/account', account, getHeaders())
})

export const accountDelete = createAction(ACCOUNT_DELETE, function(appCodename, accountId) {
    return apiCall('delete', '/api/v1/' + appCodename + '/account/' + accountId, getHeaders())
})

export const accountEdit = createAction(ACCOUNT_EDIT, function(appCodename, accountId, account) {
    return apiCall('put', '/api/v1/' + appCodename + '/account/' + accountId, account, getHeaders())
})

export const accountGetOne = createAction(ACCOUNT_GET_ONE, function(appCodename, accountId) {
    return apiCall('get', '/api/v1/' + appCodename + '/account/' + accountId, getHeaders())
})

export const accountCleanOne = createAction(ACCOUNT_CLEAN_ONE, function() {
    return Promise.resolve()
})

export const getWebhook = createAction(WEBHOOK_GET, function(appCodename, query, params) {
    const queryParams = Object.assign({}, params, query)
    return apiCall('get', '/api/v1/' + appCodename + '/webhook', getHeaders(queryParams))
})

export const getOneWebhook = createAction(WEBHOOK_GET_ONE, function(appCodename, webhookId) {
    return apiCall('get', '/api/v1/' + appCodename + '/webhook/' + webhookId, getHeaders())
})

export const getUserWebhook = createAction(GET_USER_WEBHOOK, function(appCodename, messageUserId) {
    return apiCall('get', '/api/v1/' + appCodename + '/webhook/single/' + messageUserId, getHeaders())
})

export const getBroadcastWebhook = createAction(WEBHOOK_BROADCAST_GET, function(appCodename) {
    return apiCall('get', '/api/v1/' + appCodename + '/webhook/custom/broadcast', getHeaders())
})

export const addWebhook = createAction(WEBHOOK_ADD, function(appCodename, params) {
    return apiCall('post', '/api/v1/' + appCodename + '/webhook', params, getHeaders())
})

export const editWebhook = createAction(WEBHOOK_EDIT, function(appCodename, webhookId, params) {
    return apiCall('put', '/api/v1/' + appCodename + '/webhook/' + webhookId, params, getHeaders())
})

export const setWebhook = createAction(WEBHOOK_SET, function(appCodename, accountId, accountPlatform) {
    return apiCall('get', '/api/v1/' + appCodename + '/' + accountPlatform + '/' + accountId + '/setwebhook', getHeaders())
})

export const deleteWebhook = createAction(WEBHOOK_DELETE, function(appCodename, webhookId) {
    return apiCall('delete', '/api/v1/' + appCodename + '/webhook/' + webhookId, getHeaders())
})

export const cleanWebhooks = createAction(WEBHOOK_CLEAN, function() {
    return Promise.resolve()
})

export const applyWebhook = createAction(WEBHOOK_APPLY, function(url, message) {
    return apiCall('post', url, message, getHeaders())
})

export const messageGetOne = createAction(MESSAGE_GET_ONE, function(appCodename, messageId) {
    return apiCall('get', '/api/v1/' + appCodename + '/message/' + messageId, getHeaders())
})

export const messageEdit = createAction(MESSAGE_EDIT, function(appCodename, messageId, params) {
    return apiCall('put', '/api/v1/' + appCodename + '/message/' + messageId, params, getHeaders())
})

export const messageDelete = createAction(MESSAGE_DELETE, function(appCodename, messageId) {
    return apiCall('delete', '/api/v1/' + appCodename + '/message/' + messageId, getHeaders())
})

export const messageUserGet = createAction(MESSAGE_USER_GET, function(appCodename, filter, params) {
    const queryParams = Object.assign({}, filter, params)
    if (!queryParams.query) {
        queryParams.query = {}
    }
    queryParams.sort = { updatedAt: -1 }
    queryParams.query.blockedAt = null
    queryParams.query.real = true
    return apiCall('get', '/api/v1/' + appCodename + '/messageuser?', getHeaders(queryParams))
})

export const messageUserClean = createAction(MESSAGE_USER_CLEAN, function() {
    return Promise.resolve()
})

export const messageUserGetOne = createAction(MESSAGE_USER_GET_ONE, function(appCodename, userId, params) {
    return apiCall('get', '/api/v1/' + appCodename + '/messageuser/' + userId, getHeaders(params))
})

export const messageUsersImport = createAction(MESSAGE_USER_IMPORT, function(appCodename, accountId, data) {
    return apiCall('post', '/api/v1/' + appCodename + '/messageuser/' + accountId + '/import', data, getHeaders())
})

export const messageUsersExport = createAction(MESSAGE_USER_EXPORT, function(lang, appCodename, query) {
    return apiCall('get', '/api/v1/' + appCodename + '/messageuser/export/all', getHeaders(query, lang))
})

export const conversationsExport = createAction(MESSAGE_USER_EXPORT, function (lang, appCodename, query) {
    return apiCall('get', '/api/v1/' + appCodename + '/messageuser/export/conversations', getHeaders(query, lang))
})

export const blockUser = createAction(MESSAGE_USER_BLOCK, function (appCodename, messageUserId, accountId) {
    return apiCall('put', '/api/v1/' + appCodename + '/messageuser/' + messageUserId + '/block/' + accountId, getHeaders())
})

export const apiClean = createAction(API_CLEAN, function() {
    return Promise.resolve()
})

export const isReady = createAction(IS_READY, () => true)

export const submissionGet = createAction(SUBMISSION_GET, function(appCodename, query, params) {
    const queryParams = Object.assign({}, params, query)
    return apiCall('get', '/api/v1/' + appCodename + '/submission', getHeaders(queryParams))
})

export const submissionEdit = createAction(SUBMISSION_EDIT, function(appCodename, submissionId, params) {
    return apiCall('put', '/api/v1/' + appCodename + '/submission/' + submissionId, params, getHeaders())
})

export const submissionClean = createAction(SUBMISSION_CLEAN, function() {
    return Promise.resolve()
})

export const exportReport = createAction(EXPORT_REPORT, (appCodename, fileType, chartId, query) => {
    return apiCall('get', `/api/v1/${appCodename}/stats/export/${fileType}/${chartId}`, getHeaders(query))
})

export const exportReportV2C = createAction(EXPORT_REPORT_V2C, (fileType, chartId, query) => {
    return apiCall('get', `/api/v1/stats/v2c/export/${fileType}/${chartId}`, getHeaders(query))
})

export const exportUserNPS = createAction(EXPORT_NPS, function(appCodename, lang, query) {
    return apiCall('get', '/api/v1/' + appCodename + '/stats/nps/export', getHeaders(query, lang))
})

export const exportReportOperators = createAction(EXPORT_REPORT_OPERATORS, (appCodename, query) => {
    return apiCall('get', `/api/v1/${appCodename}/stats/exportOperator`, getHeaders(query))
})

export const kueStats = createAction(KUE_STATS_GET, () => {
    return apiCall('get', '/api/v1/stats/kue', getHeaders(), { timeout: 1000 * 60 * 15 })
})

export const kueStatsDoOperation = createAction(KUE_STATS_DO_OPERATION, (params) => {
    return apiCall('get', '/api/v1/stats/kue', getHeaders(params), { timeout: 1000 * 60 * 15 })
})

export const kueStatsClear = createAction(KUE_STATS_CLEAR, (params) => {
    return Promise.resolve()
})

export const bullStats = createAction(BULL_STATS_GET, () => {
    return apiCall('get', '/api/v1/stats/bull', getHeaders(), { timeout: 1000 * 60 * 15 })
})

export const botsStats = createAction(BOTS_STATS_GET, () => {
    return apiCall('get', '/api/v1/stats/bots', getHeaders(), { timeout: 1000 * 60 * 15 })
})

export const uptimeStats = createAction(UPTIME_STATS_GET, () => {
    return apiCall('get', '/api/v1/stats/uptime', getHeaders(), { timeout: 1000 * 60 * 15 })
})

export const v2cStats = createAction(V2C_STATS_GET, (params) => {
    return apiCall('get', '/api/v1/stats/v2c', getHeaders(params))
})

export const requestChartsConfigV2C = createAction(REQUEST_CHARTS_CONFIG_V2C, (params) => {
    return apiCall('get', '/api/v1/stats/v2c/errorsBotsConfig', getHeaders(params))
})

export const requestChartDataV2C = createAction(REQUEST_CHART_DATA_V2C, (params) => {
    return apiCall('get', '/api/v1/stats/v2c/requestChartDataV2C', getHeaders(params))
})

export const gupshupBalanceStats = createAction(GUPSHUP_BALANCE_STATS_GET, (params) => {
    return apiCall('get', '/api/v1/stats/gupshupbalance', getHeaders(params))
})

export const gupshupBalanceStatsForApp = createAction(GUPSHUP_BALANCE_STATS_GET_FOR_APP, (appCodename, params) => {
    return apiCall('get', '/api/v1/app/' + appCodename + '/gupshupbalance', getHeaders(params))
})

export const addCondition = createAction(ADD_AI_CONDITION, function(appCodename, condition, query) {
    return apiCall('post', `/api/v1/${appCodename}/ai/condition`, condition, getHeaders(query))
})

export const deleteCondition = createAction(DELETE_AI_CONDITION, function(appCodename, conditionId, query) {
    return apiCall('delete', `/api/v1/${appCodename}/ai/condition/${conditionId}`, getHeaders(query))
})

export const editCondition = createAction(EDIT_AI_CONDITION, (appCodename, conditionId, condition, query) => {
    return apiCall('put', `/api/v1/${appCodename}/ai/condition/${conditionId}`, condition, getHeaders(query))
})

export const getConditions = createAction(GET_AI_CONDITIONS, function(appCodename, query, params) {
    const queryParams = Object.assign({}, params, query)
    return apiCall('get', `/api/v1/${appCodename}/ai/condition`, getHeaders(queryParams))
})

export const getCondition = createAction(GET_AI_CONDITION, function(appCodename, conditionId, query) {
    return apiCall('get', `/api/v1/${appCodename}/ai/condition/${conditionId}`, getHeaders(query))
})

export const cleanConditions = createAction(CLEAN_AI_CONDITIONS, () => true)

export const cleanCondition = createAction(CLEAN_AI_CONDITION, () => true)

export const disableCondition = createAction(DISABLE_AI_CONDITION, (conditionId, data) => {
    return {
        conditionId,
        data
    }
})

export const matchConditions = createAction(MATCH_AI_CONDITION, (appCodename, params) => {
    return apiCall('get', `/api/v1/${appCodename}/ai/condition/match/all`, getHeaders(params))
})

export const getEntities = createAction(GET_AI_ENTITIES, function(appCodename, params) {
    return apiCall('get', `/api/v1/${appCodename}/ai/wit/entities`, getHeaders(params))
}, function(appCodename, params) {
    return { appCodename, params }
})

export const addEntity = createAction(ADD_AI_ENTITY, function(appCodename, data, params) {
    return apiCall('post', `/api/v1/${appCodename}/ai/wit/entities`, data, getHeaders(params))
})

export const editEntity = createAction(EDIT_AI_ENTITY, function(appCodename, id, data, params) {
    return apiCall('put', `/api/v1/${appCodename}/ai/wit/entities/${id}`, data, getHeaders(params))
})

export const deleteEntity = createAction(DELETE_AI_ENTITY, function(appCodename, id, data) {
    return apiCall('delete', `/api/v1/${appCodename}/ai/wit/entities/${id}`, getHeaders(data))
})

export const cleanEntities = createAction(CLEAN_AI_ENTITIES, () => true)

export const addEntityValue = createAction(ADD_AI_ENTITY_VALUE, function(appCodename, id, data, params) {
    return apiCall('post', `/api/v1/${appCodename}/ai/wit/entities/${id}/values`, data, getHeaders(params))
})

export const deleteEntityValue = createAction(DELETE_AI_ENTITY_VALUE, function(appCodename, id, value, params) {
    return apiCall('delete', `/api/v1/${appCodename}/ai/wit/entities/${id}/values/${value}`, getHeaders(params))
})

export const addSynonym = createAction(ADD_AI_SYNONYM, function(appCodename, entity, value, data, params) {
    return apiCall('post', `/api/v1/${appCodename}/ai/wit/entities/${entity}/values/${value}/synonyms`, data, getHeaders(params))
})

export const deleteSynonym = createAction(DELETE_AI_SYNONYM, function(appCodename, entity, value, synonym, params) {
    return apiCall('delete', `/api/v1/${appCodename}/ai/wit/entities/${entity}/values/${value}/synonyms/${encodeURIComponent(synonym)}`, getHeaders(params))
})

export const getAnalysis = createAction(GET_AI_ANALYSIS, function(appCodename, text, params) {
    if (!params) {
        params = {}
    }
    params.text = text
    return apiCall('get', `/api/v1/${appCodename}/ai/wit/analysis`, getHeaders(params))
})

export const getSamples = createAction(GET_AI_SAMPLES, function(appCodename, id, value, params) {
    return apiCall('get', `/api/v1/${appCodename}/ai/wit/entities/${id}/values/${value}/samples`, getHeaders(params))
})

export const cleanSamples = createAction(CLEAN_AI_SAMPLES, () => true)

export const addSamples = createAction(ADD_AI_SAMPLES, function(appCodename, data, params) {
    return apiCall('post', `/api/v1/${appCodename}/ai/wit/samples`, data, getHeaders(params))
})

export const deleteSamples = createAction(DELETE_AI_SAMPLES, function(appCodename, params) {
    return apiCall('delete', `/api/v1/${appCodename}/ai/wit/samples`, getHeaders(params))
})

export const getWitStatus = createAction(GET_AI_STATUS, function(appCodename, params) {
    return apiCall('get', `/api/v1/${appCodename}/ai/wit/status`, getHeaders(params))
})

export const getExpressions = createAction(GET_AI_EXPRESSIONS, function(appCodename, taskId, query, params) {
    return apiCall('get', `/api/v1/${appCodename}/ai/expression/${taskId}`, getHeaders(Object.assign({}, query, params)))
})

export const cleanExpressions = createAction(CLEAN_AI_EXPRESSIONS, () => true)

export const editExpression = createAction(EDIT_AI_EXPRESSION, function(appCodename, id, data) {
    return apiCall('put', `/api/v1/${appCodename}/ai/expression/${id}`, data, getHeaders())
})

export const getTasks = createAction(GET_AI_TASKS, function(appCodename, query) {
    if (!query) {
        query = {}
    }
    query.sort = {
        createdAt: -1
    }
    return apiCall('get', `/api/v1/${appCodename}/ai/task`, getHeaders(query))
})

export const cleanTasks = createAction(CLEAN_AI_TASKS, function() {
    return Promise.resolve()
})

export const getTask = createAction(GET_AI_TASK, function(appCodename, taskId) {
    return apiCall('get', `/api/v1/${appCodename}/ai/task/${taskId}`, getHeaders())
})

export const cleanTask = createAction(CLEAN_AI_TASK, function() {
    return Promise.resolve()
})

export const addTasks = createAction(ADD_AI_TASKS, function(appCodename, formData, params) {
    return apiCall('post', `/api/v1/${appCodename}/ai/task`, formData, getHeaders(params))
})

export const deleteTasks = createAction(DELETE_AI_TASKS, function(appCodename, id) {
    return apiCall('delete', `/api/v1/${appCodename}/ai/task/${id}`, getHeaders())
})

export const updateStatusTasks = createAction(UPDATE_STATUS_AI_TASKS, function(task) {
    return Promise.resolve(task)
})
export const downloadTasks = createAction(DOWNLOAD_AI_TASKS, function(appCodename, task, format, lang) {
    return apiCall('get', `/api/v1/${appCodename}/ai/task/download/${task}?format=${format}`, getHeaders(null, lang))
})
export const archiveTask = createAction(ARCHIVE_AI_TASK, function(appCodename, task) {
    return apiCall('get', `/api/v1/${appCodename}/ai/task/archive/${task}`, getHeaders())
})
export const unarchiveTask = createAction(ARCHIVE_AI_TASK, function(appCodename, task) {
    return apiCall('get', `/api/v1/${appCodename}/ai/task/unarchive/${task}`, getHeaders())
})

export const trainTask = createAction(TRAIN_TASK, (appCodename, taskId, data) => {
    return apiCall('post', `/api/v1/${appCodename}/ai/task/train/${taskId}`, data, getHeaders())
})

export const downloadNLP = createAction(DOWNLOAD_AI_NLP, function(appCodename, query, lang) {
    return apiCall('get', `/api/v1/${appCodename}/ai/nlp/download`, getHeaders(query, lang))
})

export const backupsWit = createAction(BACKUPS_WIT, function (appCodename) {
    return apiCall('get', `/api/v1/${appCodename}/ai/wit/backupsExportWit`, getHeaders())
})

export const getPublicFlows = createAction(GET_PUBLIC_FLOWS, function(appCodename, query) {
    return apiCall('get', `/api/v1/app/${appCodename}/public/flows`, getHeaders(query))
})

export const cleanPublicFlows = createAction(CLEAN_PUBLIC_FLOWS, () => true)

export const getPublicResources = createAction(GET_PUBLIC_RESOURCES, function(appCodename) {
    return apiCall('get', `/api/v1/app/${appCodename}/public/resources`, getHeaders())
})

export const resourceAdd = createAction(RESOURCE_ADD, function(appCodename, data, params) {
    return apiCall('post', `/api/v1/${appCodename}/resources`, data, getHeaders(params))
})

export const resourceEdit = createAction(RESOURCE_EDIT, function(appCodename, id, data) {
    return apiCall('put', `/api/v1/${appCodename}/resources/${id}`, data, getHeaders())
})

export const cleanResource = createAction(RESOURCE_CLEAN, function() {
    return Promise.resolve()
})

export const resourceDelete = createAction(RESOURCE_DELETE, function(appCodename, resourceId) {
    return apiCall('delete', '/api/v1/' + appCodename + '/resources/' + resourceId, getHeaders())
})

export const resourcesAll = createAction(RESOURCE_GET_ALL, function(appCodename, query, params) {
    const queryParams = Object.assign({}, params, query)
    return apiCall('get', '/api/v1/' + appCodename + '/resources', getHeaders(queryParams))
})

export const cleanResourceAll = createAction(RESOURCE_GET_ALL_CLEAN, function() {
    return Promise.resolve()
})

export const resourceById = createAction(RESOURCE_GET_ONE, function(appCodename, id) {
    return apiCall('get', '/api/v1/' + appCodename + '/resources/' + id, getHeaders())
})

export const cleanResourceById = createAction(RESOURCE_GET_ONE_CLEAN, function() {
    return Promise.resolve()
})

export const resourceUploadMedia = createAction(RESOURCE_UPLOAD_MEDIA, function(appCodename, resourceId, media, onUploadProgress) {
    return apiCall('post', `/api/v1/${appCodename}/resources/${resourceId}/media/file/upload`, media, Object.assign({ onUploadProgress }, getHeadersAuthOnly()))
})

export const cleanUploadMedia = createAction(RESOURCE_UPLOAD_MEDIA_CLEAN, function() {
    return Promise.resolve()
})

export const resourceDeleteMediaResource = createAction(RESOURCE_DELETE_MEDIA, function(appCodename, resourceId, mediaId) {
    return apiCall('delete', `/api/v1/${appCodename}/resources/${resourceId}/media/${mediaId}`, getHeaders())
})

export const resourceDeleteAllMedia = createAction(RESOURCE_DELETE_ALL_MEDIA, function(appCodename, resourceId) {
    return apiCall('delete', `/api/v1/${appCodename}/resources/${resourceId}/media`, getHeaders())
})

export const cleanDeleteMediaResource = createAction(RESOURCE_DELETE_MEDIA_CLEAN, function() {
    return Promise.resolve()
})

export const saveAssetForFacebook = createAction(SAVE_ASSET_FACEBOOK, function(appCodename, resourceId) {
    return apiCall('post', `/api/v1/${appCodename}/resources/save/facebook/${resourceId}`, {}, getHeaders())
})

export const cleanSaveAssetFacebook = createAction(CLEAN_SAVE_ASSET_FACEBOOK, function() {
    return Promise.resolve()
})

export const saveAssetForTelegram = createAction(SAVE_ASSET_TELEGRAM, function(appCodename, dataMedia) {
    return apiCall('post', '/api/v1/' + appCodename + '/resources/save/telegram', dataMedia, getHeaders())
})

export const cleanSaveAssetFoRTelegram = createAction(CLEAN_SAVE_ASSET_TELEGRAM, function() {
    return Promise.resolve()
})

export const sendHSM = createAction(SEND_HSM, function(appCodename, accountPlatform, accountId, data) {
    return apiCall('post', '/api/v1/' + appCodename + '/' + accountPlatform + '/' + accountId + '/sendhsm', data, getHeaders())
})

export const getHsm = createAction(GET_HSM, (appCodename, query, params) => {
    const queryParams = Object.assign({}, params, query)
    return apiCall('get', `/api/v1/${appCodename}/hsm`, getHeaders(queryParams))
})

export const cleanHsm = createAction(CLEAN_HSM, () => {
    return Promise.resolve()
})

export const exportHsm = createAction(EXPORT_HSM, (appCodename, report, query, params, lang) => {
    return apiCall('get', `/api/v1/${appCodename}/hsm/export/${report}`, getHeaders({...query, ...params}, lang))
})

export const uploadHsmCampaign = createAction(UPLOAD_HSM_CAMPAIGN, (appCodename, formData, params) => {
    return apiCall('post', `/api/v1/${appCodename}/hsm/campaign/upload`, formData, getHeaders(params))
})

export const getLimitHsmSend = createAction(GET_LIMIT_HSM_SEND, (appCodename, accountId) => {
    return apiCall('get', `/api/v1/${appCodename}/hsm/getLimitHsmSend`, getHeaders(accountId))
})

export const updateLimitSendHsm = createAction(UPDATE_LIMIT_HSM_SEND, (appCodename, data) => {
    return apiCall('put', `/api/v1/${appCodename}/hsm/updateLimitSendHsm`, data, getHeaders())
})

export const getHSMTemplate = createAction(GET_HSM_TEMPLATE, (appCodename, id) => {
    return apiCall('get', `/api/v1/${appCodename}/hsmtemplate/${id}`, getHeaders())
})

export const getHSMTemplates = createAction(GET_HSM_TEMPLATES, (appCodename, query, params) => {
    const queryParams = Object.assign({}, params, query)
    return apiCall('get', `/api/v1/${appCodename}/hsmtemplate`, getHeaders(queryParams))
})

export const addHSMTemplate = createAction(ADD_HSM_TEMPLATE, function(appCodename, data) {
    return apiCall('post', `/api/v1/${appCodename}/hsmtemplate`, data, getHeaders())
})

export const editHSMTemplate = createAction(EDIT_HSM_TEMPLATE, function(appCodename, id, data) {
    return apiCall('put', `/api/v1/${appCodename}/hsmtemplate/${id}`, data, getHeaders())
})

export const deleteHSMTemplate = createAction(DELETE_HSM_TEMPLATE, function(appCodename, id) {
    return apiCall('delete', `/api/v1/${appCodename}/hsmtemplate/${id}`, getHeaders())
})

export const cleanHSMTemplates = createAction(CLEAN_HSM_TEMPLATES, () => {
    return Promise.resolve()
})

export const getUnknownExpressions = createAction(UNKNOWN_EXPRESSION_GET, (appCodename, query, params) => {
    const queryParams = Object.assign({}, params, query)
    return apiCall('get', '/api/v1/' + appCodename + '/unknownexpression', getHeaders(queryParams))
})

export const cleanUnknownExpressions = createAction(UNKNOWN_EXPRESSION_CLEAN, () => {
    return Promise.resolve()
})

export const deleteUnknownEpxression = createAction(UNKNOWN_EXPRESSION_DELETE, function(appCodename, id) {
    return apiCall('delete', `/api/v1/${appCodename}/unknownexpression/${id}`, getHeaders())
})

export const undeleteUnknownEpxression = createAction(UNKNOWN_EXPRESSION_UNDELETE, function(appCodename, id) {
    return apiCall('put', `/api/v1/${appCodename}/unknownexpression/${id}/undelete`, _, getHeaders())
})

export const getUnknownCategory = createAction(UNKNOWN_CATEGORY_GET, (appCodename, query, params) => {
    const queryParams = Object.assign({}, params, query)
    return apiCall('get', '/api/v1/' + appCodename + '/unknowncategory', getHeaders(queryParams))
})

export const cleanUnknownCategory = createAction(UNKNOWN_CATEGORY_CLEAN, () => {
    return Promise.resolve()
})

export const getUnknownConversations = createAction(UNKNOWN_CONVERSATION_GET, (appCodename, query, params) => {
    const queryParams = Object.assign({}, params, query)
    return apiCall('get', '/api/v1/' + appCodename + '/unknownconversation', getHeaders(queryParams))
})

export const cleanUnknownConversations = createAction(UNKNOWN_CONVERSATION_CLEAN, () => {
    return Promise.resolve()
})

export const cleanAllUnknownExpressions = createAction(UNKNOWN_EXPRESSION_CLEAN_ALL, function(appCodename) {
    return apiCall('get', `/api/v1/${appCodename}/unknownexpression/clean/all`, getHeaders())
})

export const downloadUnknownExpression = createAction(DOWNLOAD_UNKNOWN_EXPRESSION, function (appCodename, fileType, lang, startDate, endDate) {
    return apiCall('get', `/api/v1/${appCodename}/unknownexpression/download/all`, getHeaders({ fileType, startDate, endDate }, lang))
})

export const changedGlobalLimit = createAction(CHANGED_GLOBAL_LIMIT, (limit) => {
    return limit
})

export const changedSkip = createAction(CHANGED_SKIP, (skip) => {
    return skip
})

export const cleanSkip = createAction(CLEAN_CHANGED_SKIP, () => {
    return Promise.resolve()
})

export const getUnknownFilters = createAction(UNKNOWN_FILTER_GET, (appCodename, query = {}) => {
    return apiCall('get', '/api/v1/' + appCodename + '/unknownfilter', getHeaders(query))
})

export const cleanUnknownFilters = createAction(UNKNOWN_FILTER_CLEAN, () => {
    return Promise.resolve()
})

export const getConversation = createAction(GET_CONVERSATION, (appCodename, messageUserId) => {
    return apiCall('get', `/api/v1/${appCodename}/messageuser/${messageUserId}`, getHeaders({
        populates: '[{"key":"account"}]'
    }))
})

export const cleanConversation = createAction(CLEAN_CONVERSATION, () => {
    return true
})

export const getConversations = createAction(GET_CONVERSATIONS, (appCodename, filter) => {
    return apiCall('get', `/api/v1/${appCodename}/messageuser`, getHeaders(filter))
})

export const cleanConversations = createAction(CLEAN_CONVERSATIONS, () => {
    return true
})

export const webhookAction = createAction(WEBHOOK_ACTION, (appCodename, webhookAction, messageUserId, data) => {
    return apiCall('post', `/api/v1/${appCodename}/webhook/${webhookAction}/${messageUserId}`, data, getHeaders())
})

export const loadCache = createAction(LOAD_CACHE, stores => stores)

export const saveCache = createAction(SAVE_CACHE, (messageUserId, stores) => {
    return { messageUserId, stores }
})

export const cleanCache = createAction(CLEAN_CACHE, () => true)

export const getMessages = createAction(GET_MESSAGES, (appCodename, query) => {
    return apiCall('get', `/api/v1/${appCodename}/message`, getHeaders(query))
})

export const getOldMessages = createAction(GET_OLD_MESSAGES, (appCodename, messageUser, createdAt, limit) => {
    return apiCall('get', `/api/v1/${appCodename}/message/query/old`, getHeaders({
        messageUser,
        createdAt,
        limit
    }))
})

export const getNewMessages = createAction(GET_NEW_MESSAGES, (appCodename, messageUser, createdAt, limit) => {
    return apiCall('get', `/api/v1/${appCodename}/message/query/new`, getHeaders({
        messageUser,
        createdAt,
        limit
    }))
})

export const receiveMessage = createAction(RECEIVE_MESSAGE, message => {
    return Promise.resolve(message)
})

export const updateMessage = createAction(UPDATE_MESSAGE, message => {
    return message
})

export const cleanMessages = createAction(CLEAN_MESSAGES, () => {
    return true
})

export const pause = createAction(PAUSE, (url, conversation, optionalTo) => {
    const data = { conversation }
    if (optionalTo) {
        data.to = optionalTo
    }
    return apiCall('post', url, {}, getHeaders(data))
})

export const unpause = createAction(UNPAUSE, (url, conversation) => {
    return apiCall('post', url, {}, getHeaders({ conversation }))
})

export const messageReceive = createAction(MESSAGE_RECEIVE, message => {
    return message
})

export const speech = createAction(SPEECH_TO_TEXT, (appCodename, messageId) => {
    return apiCall('get', `/api/v1/${appCodename}/message/${messageId}/speech`, getHeaders())
})

export const sendEmail = createAction(SEND_EMAIL, email => {
    return apiCall('post', '/api/v1/email/send', email, getHeaders())
})

export const upload = createAction(UPLOAD, (appCodename, media, params) => {
    return apiCall('post', `/api/v1/${appCodename}/resources/upload/s3`, media, getHeadersAuthOnly(params))
})

export const requestChartsConfig = createAction(REQUEST_CHARTS_CONFIG, (appCodename, params) => {
    return apiCall('get', `/api/v1/${appCodename}/stats/config`, getHeaders(params))
})

export const requestBigQueryData = createAction(REQUEST_BIGQUERY_DATA, (appCodename, params) => {
    return apiCall('get', `/api/v1/${appCodename}/stats/bigquery`, getHeaders(params))
})

export const requestChartData = createAction(REQUEST_CHART_DATA, (appCodename, params) => {
    return apiCall('get', `/api/v1/${appCodename}/stats/data`, getHeaders(params))
})

export const servicesConfig = createAction(GET_SERVICES_CONFIG, function(appCodename) {
    return apiCall('get', `/api/v1/app/${appCodename}/services`, getHeaders())
})

export const localeGetAll = createAction(GET_LOCALES, (appCodename, bot, language, compare) => {
    return apiCall('get', `/api/v1/${appCodename}/locale/language/${language}`, getHeaders({ bot, compare }))
})

export const localeUpdate = createAction(UPDATE_LOCALE, (appCodename, bot, language, type, name, text, originalText) => {
    return apiCall('put', `/api/v1/${appCodename}/locale/language/${language}`, { bot, name, type, text, originalText }, getHeaders())
})

export const previousPath = createAction(PREVIOUSPATH_SET, (path) => path)

// STATS OPERATORS
export const statsOperator = createAction(GET_STATS_OPERATOR, (appCodename, params) => {
    return apiCall('get', `/api/v1/${appCodename}/stats/operator`, getHeaders(params))
})

export const operatorsClean = createAction(OPERATORS_CLEAN, function () {
    return Promise.resolve()
})

// BOTS
export const botsRetrieveAll = createAction(BOTS_GET, (query) => {
    return apiCall('get', '/api/v1/bots', getHeaders(query))
})

export const botsCleanAll = createAction(BOTS_CLEAN, () => Promise.resolve())

export const botAdd = createAction(BOTS_ADD, (data) => {
    return apiCall('post', `/api/v1/bots`, data, getHeaders())
})

export const releasesAdd = createAction(RELEASES_ADD, (appCodename, data) => {
    return apiCall('post', `/api/v1/${appCodename}/bots/release`, data, getHeaders())
})

export const releasesDelete = createAction(RELEASES_DELETE, (appCodename, target) => {
    return apiCall('delete', `/api/v1/${appCodename}/bots/release/${target}`, getHeaders())
})

export const releasesRetrieveAll = createAction(RELEASES_GET, (appCodename) => {
    return apiCall('get', `/api/v1/${appCodename}/bots/release`, getHeaders())
})

export const releasesDiff = createAction(RELEASES_DIFF, (appCodename, query) => {
    return apiCall('get', `/api/v1/${appCodename}/bots/release/diff`, getHeaders(query))
})

export const releasesMerge = createAction(RELEASES_MERGE, (appCodename, data) => {
    return apiCall('put', `/api/v1/${appCodename}/bots/release/merge`, data, getHeaders())
})

// GLOBAL CONFIGURATIONS
export const getGlobalConfig = createAction(GET_GLOBAL_CONFIG, (appCodename) => {
    return apiCall('get', `/api/v1/app/${appCodename}/config`, getHeaders())
})

export const selectBot = createAction(SELECT_BOT, (bot) => bot)

export const selectWit = createAction(SELECT_WIT, (wit) => wit)

// BILLING
export const changedNonPayment = createAction(CHANGED_BILLING, (appCodename, data) => {
    return apiCall('post', `/api/v1/app/${appCodename}/billing/save`, data, getHeaders({}))
})

export const getBilling = createAction(GET_BILLING, (appCodename) => {
    return apiCall('get', `/api/v1/app/${appCodename}/billing/get`, getHeaders({}))
})

// DERIVE TO CUSTOMER

export const deriveCustomer = createAction(DERIVE_CUSTOMER, function (appCodename, data) {
    return apiCall('post', `/api/v1/${appCodename}/deriveuser/derive`, data, getHeaders())
})

export const getCustomerData = createAction(GET_CUSTOMER, function (appCodename, accountId, messageUserId, appId) {
    return apiCall('get', `/api/v1/${appCodename}/deriveuser/getcustomerdata`, getHeaders({ accountId, messageUserId, appId }))
})

export const checkAvailabilityCustomer = createAction(AVAILABILITY_CUSTOMER, function (appCodename, accountId) {
    return apiCall('get', `/api/v1/${appCodename}/deriveuser/checkavailability`, getHeaders({ accountId }))
})

export const getOriginCustomer = createAction(GET_ORIGINS_CUSTOMER, function (appCodename, accountId) {
    return apiCall('get', `/api/v1/${appCodename}/deriveuser/origin`, getHeaders({ accountId }))
})

export const getOperatorCustomer = createAction(GET_OPERATOR_CUSTOMER, function (appCodename, accountId) {
    return apiCall('get', `/api/v1/${appCodename}/deriveuser/operator`, getHeaders({ accountId }))
})

export const cleanCustomer = createAction(CLEAN_CUSTOMER, () => {
    return true
})

// STATUS
export const cleanStatusCT = createAction(CLEAN_STATUS_CT, () => true)

export const setStatusCT = createAction(SET_STATUS_CT, data => data)


// FACEBOOK

export const facebookAppSubscriptions = createAction(FACEBOOK_APP_SUBSCRIPTIONS, function (appCodename, accountId, token) {
    return apiCall('post', `/api/v1/${appCodename}/messenger/${accountId}/fbappsubscriptions`, { token }, getHeaders())
})

export const facebookAppSetupLinkedTokens = createAction(FACEBOOK_APP_SUBSCRIPTIONS, function (appCodename, accountId, token, pageId, pageName, instagramId, instagramName) {
    return apiCall('post', `/api/v1/${appCodename}/messenger/${accountId}/fbappsetuplinkedtoken`, { token, pageId, pageName, instagramId, instagramName }, getHeaders())
})
