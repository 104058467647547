import * as actions from '../actions'
const defaultState = null
const statsFromAction = (action) => {
    const stats = {}
    const queueNames = action.payload.data.stats && Object.keys(action.payload.data.stats)
    queueNames.forEach(queueName => {
        const queueValue = action.payload.data.stats[queueName] || 0
        if (queueValue) {
            stats[queueName] = queueValue
        }
    })
    return stats
}

export default function kueStats(state = defaultState, action) {
    let newState = Object.assign({}, state)
    if (!newState) {
        newState = {}
    }
    switch (action.type) {
        case actions.KUE_STATS_GET:
            if (action && !action.error && action.payload && action.payload.data) {
                newState.updated = new Date()
                newState.stats = statsFromAction(action)
                const queueNames = action.payload.data.stats && Object.keys(action.payload.data.stats)
                queueNames.forEach(queueName => {
                    const queueValue = action.payload.data.stats[queueName] || 0
                    if (queueValue) {
                        newState.stats[queueName] = queueValue
                    }
                })
            }
            return newState

        case actions.KUE_STATS_DO_OPERATION:
            if (action && !action.error && action.payload && action.payload.data) {
                newState.updated = new Date()
                newState.stats = statsFromAction(action)
                newState.queueName = action.payload.data.queueName
                newState.operation = action.payload.data.operation
                newState.operationData = action.payload.data.operationData
                newState.operationDataLimited = action.payload.data.operationDataLimited
            }
            return newState

        case actions.KUE_STATS_CLEAR:
            newState = {}
            return newState

        default:
            return state
    }
}
